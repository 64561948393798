import { useTranslation } from "react-i18next";
import IconArrowRight from "../../../svg/ic_arrow_right.svg";
import AnimatedTap from "../../AnimatedTap";
import { HamburgerMenuPage } from "../HamburgerMenu";
import HamburgerMenuLink from "../HamburgerMenuLink";
import HamburgerMenuLinks from "../HamburgerMenuLinks";
import HamburgerMenuUserCard from "../HamburgerMenuUserCard";
import LanguagePicker from "../../LanguagePicker";
import Search from "../../Search";
import { useState, useEffect } from "react";

type HamburgerMenuMainPageProps = {
  links: HamburgerMenuLink[];
  setPage: React.Dispatch<React.SetStateAction<HamburgerMenuPage>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  trainerId?: string;
};

const HamburgerMenuMainPage: React.FC<HamburgerMenuMainPageProps> = ({ links, setPage, setIsOpen, trainerId }) => {
  const { t: translate } = useTranslation("common");
  const [searchFocused, setSearchFocused] = useState(true);

  return (
    <div className="flex text-lg flex-col divide-y divide-gray-700">
      <HamburgerMenuLinks links={links} onLinkClicked={() => setIsOpen(false)} />
      <div>
        <AnimatedTap className="">
          <div className="px-12 py-8 items-center cursor-pointer flex justify-between" onClick={() => setPage("categories")}>
            <p>{translate("common:m-layout.categories")}</p> <IconArrowRight />
          </div>
        </AnimatedTap>
      </div>
      <div className="flex flex-col gap-8 py-8 px-12">
        <Search focused={searchFocused} setFocused={setSearchFocused} onUserClick={() => setIsOpen(false)} />
        <LanguagePicker />
        <HamburgerMenuUserCard trainerId={trainerId} onClick={() => setPage("user-controls")} />
      </div>
    </div>
  );
};

export default HamburgerMenuMainPage;
