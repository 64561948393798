import { AnimatePresence, motion } from "framer-motion";
import React, { ReactNode, useState } from "react";
import LoadingSpinner from "./LoadingSpinner";
import PlayIcon from "../svg/ic_play_filled.svg";
import CartIcon from "../svg/ic_cart.svg";
import { cn } from "../lib/cn";

const variantClassnames = {
  modern:
    "text-[1.2rem] group h-12 rounded-lg text-black bg-white hover:bg-black active:transition active:bg-white active:text-black disabled:bg-gray-400 active:ring-2 ring-inset",
  cart: "group h-12 bg-primary-hover hover:bg-primary rounded-lg pl-2 leading-6 disabled:bg-gray-400 active:ring-2 ring-inset",
  primary:
    "group h-12 rounded-lg disabled:text-gray-700 bg-primary-hover hover:bg-primary active:bg-primary-hover disabled:bg-gray-400 active:ring-2 ring-inset",
  red: "group h-12 rounded-lg bg-red-600 hover:bg-red-700 active:bg-red-600",
  secondary:
    "h-12 rounded-lg text-gray-900 active:text-gray-900 disabled:text-gray-400 bg-white hover:bg-primary active:bg-white disabled:bg-gray-700 active:ring-2 ring-inset",
  header:
    "h-12 rounded-lg text-primary active:text-white disabled:text-gray-400 bg-white hover:bg-primary active:bg-white disabled:bg-gray-700 active:ring-2 ring-inset",
  circle: "group h-12 rounded-full disabled:text-gray-700 bg-none hover:bg-none active:bg-none disabled:bg-gray-400 active:ring-2 ring-inset",
  text: "gap-x-3 text-primary-hover hover:text-primary disabled:text-gray-400",
  transparent: "p-0 hover:text-primary-hover",
  none: "",
  outlined:
    "h-12  rounded-lg text-gray-900 active:text-gray-900 border-2 hover:border-gray-900 disabled:text-gray-400 bg-white hover:bg-gray-900 active:bg-white disabled:bg-gray-700 ",
  primarybox:
    "group h-12 disabled:text-gray-700 bg-primary-hover rounded-lg hover:bg-primary active:bg-primary disabled:bg-gray-400 active:ring-2 ring-inset",
};

const sizeVariants = {
  min: "",
  small: "w-16",
  medium: "w-20",
  large: "w-40",
  full: "w-full",
};

type ButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  icon?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  iconRight?: boolean;
  variant?: keyof typeof variantClassnames;
  size?: keyof typeof sizeVariants;
  awaitOnClick?: () => Promise<void>;
  className?: string;
  animateIcon?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  children,
  icon,
  disabled,
  loading,
  iconRight,
  variant = "primary",
  size = "large",
  onClick,
  className = "",
  type = "button",
  awaitOnClick,
  animateIcon = true,
  ...props
}) => {
  const [onClickRunning, setOnClickRunning] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  return (
    <button
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      disabled={disabled}
      className={cn(
        `relative flex overflow-hidden ${sizeVariants[size]} ${iconRight ? "flex-row-reverse" : ""} items-center ${variantClassnames[variant]} ${
          disabled ? "cursor-not-allowed ring-transparent" : "ring-primary-hover"
        } ${loading ? "cursor-wait" : ""} ${variant === "text" ? "gap-x-3" : ""} ${
          variant != "transparent" && "hover:text-white"
        } transform active:scale-95 transition duration-150`,
        className
      )}
      onClick={async (e) => {
        if (loading || onClickRunning) return;

        if (awaitOnClick) {
          try {
            setOnClickRunning(true);
            await awaitOnClick?.();
          } catch (err) {
            throw err;
          } finally {
            setOnClickRunning(false);
          }
        } else onClick?.(e);
      }}
      type={type}
      {...props}
    >
      {icon && (
        <div
          className={`${
            variant !== "text"
              ? `flex items-center justify-center ${variant === "circle" ? "rounded-full bg-none" : "rounded-lg"} w-8 h-8 ml-2 ${
                  variant !== "secondary" && variant !== "header" && variant !== "red" && variant !== "outlined" && variant !== "circle"
                }`
              : ""
          }`}
        >
          {icon}
        </div>
      )}
      <div className={`relative h-full flex grow justify-center items-center ${variant !== "text" && children ? (icon ? "pr-5" : "px-5") : ""}`}>
        {children && (variant === "primary" || variant === "red" || variant === "secondary") ? (
          <>
            <AnimatePresence initial={false}>
              {(loading || onClickRunning) && (
                <motion.div
                  className="absolute w-full h-full flex items-center justify-center"
                  initial={{ top: "-100%" }}
                  animate={{ top: 0 }}
                  exit={{ top: "100%" }}
                >
                  <LoadingSpinner color={variant === "primary" ? "white" : "accent"} />
                </motion.div>
              )}
            </AnimatePresence>
            <AnimatePresence initial={false}>
              {!loading && !onClickRunning && (
                <motion.div
                  className="absolute w-full h-full flex items-center justify-center"
                  initial={{ top: "-100%" }}
                  animate={{ top: 0 }}
                  exit={{ top: "100%" }}
                >
                  {children}
                </motion.div>
              )}
            </AnimatePresence>
            <div className="opacity-0 pointer-events-none">{children}</div>
          </>
        ) : variant === "modern" && animateIcon ? (
          <>
            <motion.div
              className="absolute w-full h-full flex items-center justify-center"
              initial={{ opacity: 1, top: "-100%" }}
              animate={{
                top: !isHovered ? "-100%" : 0,
              }}
            >
              <PlayIcon className="w-5 h-5" />
            </motion.div>
            <motion.div
              className="absolute w-full h-full flex items-center justify-center"
              initial={{ opacity: 1, scale: 1 }}
              animate={{
                opacity: isHovered ? 0 : 1,
                top: isHovered ? "100%" : 1,
              }}
            >
              {children}
            </motion.div>
          </>
        ) : variant === "cart" ? (
          <>
            <motion.div
              className="absolute"
              initial={{ opacity: 1, top: "-100%" }}
              animate={{
                top: !isHovered ? "-100%" : "25%",
              }}
            >
              <CartIcon className="w-7 h-7" />
            </motion.div>
            <motion.div
              className=""
              initial={{ opacity: 1, scale: 1 }}
              animate={{
                opacity: isHovered ? 0 : 1,
                top: isHovered ? "100%" : 1,
              }}
            >
              {children}
            </motion.div>
          </>
        ) : (
          children
        )}
      </div>
    </button>
  );
};

export default Button;
