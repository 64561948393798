import Link from "next/link";
import { useContext, useEffect, useRef, useState } from "react";

import useConsentStore from "../lib/hooks/useConsentStore";
import Modal from "../types/Modal";
import Button from "./Button";
import Checkbox from "./Checkbox";
import ModalContext from "./context/ModalContext";
import WindowModal from "./WindowModal";
import { useViewport } from "../lib/hooks/useViewport";
import useHasMounted from "../lib/hooks/useHasMounted";
import { useTranslation } from "next-i18next";

type CookieConsentModalProps = {
  modal: Modal;
};

const CookieConsentModal: React.FC<CookieConsentModalProps> = ({ modal }) => {
  const [analyticsCheck, setAnalyticsCheck] = useState(true);
  const [adsCheck, setAdsCheck] = useState(true);
  const consent = useConsentStore();

  const accept = () => {
    consent.setAds(adsCheck);
    consent.setAnalytical(analyticsCheck);
    consent.setAccepted();
    modal.hide();
  };
  const { t: translate } = useTranslation("common");
  return (
    <WindowModal size="full">
      <div className="w-full max-w-6xl px-20 py-16 space-y-8">
        <div className="space-y-4">
          <p className="font-bold">{translate("common:cookies.cookie-settings")}</p>
          <p>{translate("common:cookies.cookie-settings-p")}</p>
        </div>
        <div className="space-y-4">
          <p className="font-bold">{translate("common:cookies.core-operation-cookies-and-data-usage")}:</p>
          <p className="pb-3">{translate("common:cookies.core-operation-cookies-and-data-usage-p-1")}</p>
          <p>
            {translate("common:cookies.core-operation-cookies-and-data-usage-p-2")}{" "}
            <Link href="privacy">
              <a>{translate("common:privacy-policy")}</a>
            </Link>{" "}
            {translate("common:cookies.core-operation-cookies-and-data-usage-p-3")}
          </p>
        </div>
        <div className="space-y-4">
          <Checkbox checked={analyticsCheck} setChecked={setAnalyticsCheck}>
            <p className="font-bold">{translate("common:cookies.analytical-cookies-and-data-usage") + ":"}</p>
          </Checkbox>
          <p>{translate("common:cookies.analytical-cookies-and-data-usage-p")}</p>
        </div>
        <div className="space-y-4">
          <Checkbox checked={adsCheck} setChecked={setAdsCheck}>
            <p className="font-bold">{translate("common:cookies.advertisement-cookies-and-data-usage")}:</p>
          </Checkbox>
          <p>{translate("common:cookies.advertisement-cookies-and-data-usage-p")}</p>
        </div>
        <div className="flex flex-col text-center space-y-4 py-11">
          <p className="text-gray-400">
            {translate("common:cookies.by-clicking-accept")}{" "}
            <Link href="tos">
              <a className="text-primary">{translate("common:terms-of-service")}</a>
            </Link>{" "}
            {translate("common:cookies.and")}{" "}
            <Link href="privacy">
              <a className="text-primary">{translate("common:privacy-policy")}</a>
            </Link>
            .
          </p>
          <div className="flex w-full max-w-xl gap-5 ml-auto mr-72">
            <Button variant="secondary" onClick={() => modal.hide()}>
              {translate("common:cookies.back")}
            </Button>
            <Button size="full" onClick={() => accept()}>
              {translate("common:cookies.accept")}
            </Button>
          </div>
        </div>
      </div>
    </WindowModal>
  );
};

type CookieConsentMobileModalProps = {
  modal: Modal;
};

const CookieConsentMobileModal: React.FC<CookieConsentMobileModalProps> = ({ modal }) => {
  const consent = useConsentStore();
  const { t: translate } = useTranslation("common");
  const accept = () => {
    consent.setAds(true);
    consent.setAnalytical(true);
    consent.setAccepted();
    modal.hide();
  };
  return (
    <WindowModal size="narrow">
      <div className="w-full px-4 py-4 space-y-5">
        <div className="relative flex w-full items-center justify-center text-center gap-5">
          <h3 className="font-bold w-60">{translate("common:cookies.we-respect-the-security-of-your-personal-information")}</h3>
        </div>
        <div className="text-justify w-full leading-7">
          <p className="w-full">{translate("common:cookies.we-respect-the-security-of-your-personal-information-p")}</p>
        </div>
        <div className="px-5 text-center">
          <p className="text-gray-400">
            {translate("common:cookies.by-clicking-accept")}{" "}
            <Link href="privacy">
              <a className="text-primary">{translate("common:privacy-policy")}</a>
            </Link>{" "}
            and{" "}
            <Link href="tos">
              <a className="text-primary">{translate("common:terms-of-service")}</a>
            </Link>{" "}
          </p>
        </div>
        <Button className="mx-auto" onClick={() => accept()}>
          {translate("common:cookies.accept")}
        </Button>
      </div>
    </WindowModal>
  );
};

const CookieConsent = () => {
  const { isMobileView } = useViewport();

  const hasMounted = useHasMounted();

  const modalContext = useContext(ModalContext);
  const consent = useConsentStore();

  const acceptAll = () => {
    consent.setAds(true);
    consent.setAnalytical(true);
    consent.setAccepted();
  };

  const hasShownMobileModal = useRef(false);
  useEffect(() => {
    if (!hasShownMobileModal.current && isMobileView && !consent.accepted) {
      hasShownMobileModal.current = true;
      modalContext.show(CookieConsentMobileModal, {});
    }
  }, [consent.accepted, isMobileView]);

  const { t: translate } = useTranslation("common");

  if (!hasMounted || isMobileView || consent.accepted) return null;

  return (
    <div className="z-1000 fixed bottom-0 left-0 flex w-full bg-gray-800 rounded-xl py-16 px-16 gap-20 items-center">
      <div className="w-full max-w-7xl">
        <p>{translate("common:cookies.popup-text-1")}</p>
        <p>
          {translate("common:cookies.popup-text-2")}{" "}
          <Link href="privacy">
            <a className="text-primary">{translate("common:privacy-policy")}</a>
          </Link>{" "}
          {translate("common:cookies.and")}{" "}
          <Link href="tos">
            <a className="text-primary">{translate("common:terms-of-service")}</a>
          </Link>{" "}
          {translate("common:cookies.popup-text-3")}
        </p>
      </div>
      <div className="ml-auto space-y-5">
        <Button onClick={() => acceptAll()}>{translate("common:cookies.accept")}</Button>
        <Button variant="secondary" onClick={() => modalContext.show(CookieConsentModal, {})}>
          {translate("common:cookies.manage")}
        </Button>
      </div>
    </div>
  );
};
export default CookieConsent;
