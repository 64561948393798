import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { useTranslation } from "next-i18next";
import { useQuery } from "react-query";
import { getTrainersLike } from "../api/wrapper";
import useDebounce from "../lib/hooks/useDebounce";

import IconSearch from "../svg/ic_search.svg";
import LazyLoadedImg from "./LazyLoadedImg";
import useMobileAppStore from "../lib/hooks/useMobileAppStore";

type SearchProps = {
  focused: boolean;
  setFocused: (focused: boolean) => void;
  onUserClick?: () => void;
};

const Search: React.FC<SearchProps> = ({ focused, setFocused, onUserClick }) => {
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 500);
  const { t: translate } = useTranslation("common");
  const ref = useOnclickOutside(() => setFocused(false));
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { data: users, refetch } = useQuery(`trainers`, () => getTrainersLike(debouncedQuery), {
    enabled: false,
  });

  useEffect(() => {
    if (debouncedQuery && debouncedQuery.length > 0) refetch();
  }, [debouncedQuery]);
  const { isMobileAppView } = useMobileAppStore();
  return (
    <div className={`relative xl:grow ${focused ? "grow" : ""} xl:max-w-xs`} ref={ref}>
      <div
        onClick={() => inputRef.current?.focus()}
        className={`flex items-center rounded-2xl ${
          !isMobileAppView && "px-4"
        } py-2 h-14 xl:bg-black xl:bg-opacity-10 ring-gray-700 focus-within:bg-gray-900 focus-within:bg-opacity-100 transition-colors gap-2`}
      >
        <IconSearch className="w-6 h-6" />
        <input
          ref={inputRef}
          placeholder={translate("common:search-channels") ?? "Search channels"}
          className={`text-gray-400 bg-black bg-opacity-0 ${focused ? "w-auto" : "w-0 -mr-2 xl:mr-0"} xl:w-auto`}
          onFocus={() => setFocused(true)}
          onChange={(e) => setQuery(e.currentTarget.value)}
        />
      </div>
      {focused && query.length > 0 && users && users.length > 0 && (
        <div className="absolute inset-x-0 top-16 p-2 flex flex-col gap-2 bg-gray-900 ring-1 ring-gray-700 shadow-lg shadow-gray-900 z-900 rounded-2xl">
          {users?.map(
            (user) =>
              user.name && (
                <Link href={`/profiles/${user.slug}`} key={user.slug}>
                  <div
                    onClick={() => {
                      setFocused(false);
                      onUserClick && onUserClick();
                    }}
                    className="flex items-center rounded-xl cursor-pointer gap-4 px-4 py-2 hover:bg-gray-400 hover:bg-opacity-10"
                  >
                    <LazyLoadedImg className="w-14 h-14 rounded-xl" srcSet={user.profileImagePaths} />
                    <p>{user.name}</p>
                  </div>
                </Link>
              )
          )}
        </div>
      )}
    </div>
  );
};

export default Search;
