import { useTranslation } from "next-i18next";
import { useOwnTrainerProfile } from "../../api/hooks";
import { useViewport } from "./useViewport";

const useUserControlLinks = () => {
  const { data } = useOwnTrainerProfile();
  const { isMobileView } = useViewport();
  const { t: translate } = useTranslation("common");
  let results: { label: string; href: string }[] = [];

  if (data?.id) {
    if (isMobileView)
      results.push(
        { label: translate("common:user-control-links.my-profile"), href: `/profiles/${data.slug}` },
        { label: translate("common:user-control-links.streamer-dashboard"), href: "/dashboard/videos" }
      );
    else results.push({ label: translate("common:user-control-links.streamer-dashboard"), href: "/dashboard/videos" });
  }

  results.push({ label: translate("common:user-control-links.account-and-profile"), href: "/dashboard" });

  if (!isMobileView) {
    results.push({
      label: translate("common:user-control-links.subscriptions-and-purchases"),
      href: "/subscriptions",
    });
  }

  results.push({
    label: translate("common:user-control-links.log-out"),
    href: "/logout",
  });

  return results;
};

export default useUserControlLinks;
