import useViewportSize from "./useViewportSize";
import isServer from "../isServer";
import { screens } from "../screens";

export const useViewport = () => {
  const { width, height } = useViewportSize();

  const isLandscape = width > height;
  const isMobileView = width < parseInt(screens["md"].slice(0, -2));

  return { isLandscape, isMobileView, isServer };
};
