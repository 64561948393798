import countries from "countries-list";
import { Form, Formik } from "formik";
import { HTTPError } from "ky";
import { useRouter } from "next/router";
import { Dispatch, useContext, useEffect, useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import * as Yup from "yup";

import apiClient from "../../api/apiClient";
import { Connection, ThumbnailUploadResponse, User } from "../../api/entities";
import { useCategories, useOwnTrainerProfile, useSelf } from "../../api/hooks";
import { getAccountConnects, getTrainerByID, getVideoByID } from "../../api/wrapper";
import Button from "../../components/Button";
import AlertContext from "../../components/context/AlertContext";
import ModalContext from "../../components/context/ModalContext";

import InputField from "../../components/InputField";
import MainLayout from "../../components/layouts/MainLayout";
import LazyLoadedImg from "../../components/LazyLoadedImg";
import EmailSentModal from "../../components/modal/EmailSentModal";
import MultiSelectListBoxField from "../../components/MultiSelectListBoxField";
import VidAndThumbField, { VidAndThumb } from "../../components/VidAndThumbField";
import useUserStore from "../../lib/hooks/useUserStore";
import CustomNextPage from "../../types/CustomNextPage";
import ImageSourceSet from "../../types/ImageSourceSet";
import ConfirmationModal from "../../components/modal/ConfirmationModal";
import IconCheck from "../../svg/ic_check.svg";
import IconClose from "../../svg/ic_close.svg";
import IconDelete from "../../svg/ic_delete.svg";
import Modal from "../../types/Modal";
import ListBoxField from "../../components/ListBoxField";
import { currencies, streamerTypes } from "../../lib/constants";
import { useTranslation } from "react-i18next";
import { useViewport } from "../../lib/hooks/useViewport";

const DashboardEdit: CustomNextPage<{
  setLayoutProps: Dispatch<any>;
  modal: Modal;
}> = ({ modal, setLayoutProps }) => {
  const queryClient = useQueryClient();
  const { t: translate } = useTranslation("common");
  const countryCodes = Object.keys(countries.countries);
  const countryNames = Object.values(countries.countries).map((item) => item.name);
  const router = useRouter();
  const self = useSelf();
  const { isLandscape } = useViewport();
  let isMobileAppView = false || router.query.app !== "undefined";
  const { isLoading, error, data } = useOwnTrainerProfile();
  const [currency, setCurrency] = useState(data?.currency);

  const alert = useContext(AlertContext);
  const modalContext = useContext(ModalContext);
  const connections = useQuery("getAccountConnects", () => getAccountConnects());
  let initialStreamerType = "";
  const [changed, setChanged] = useState(false);
  if (data?.streamerType === "event" || (data?.streamerType === "Event" && !changed)) {
    initialStreamerType = data?.streamerType;
  }

  const handleEditFormSubmit = async (values: {
    username: string;
    name: string;
    motto: string;
    bio: string;
    certifications: string;
    streamerType: number;
    categories: number[];
    eventTicketPrice: number;
  }) => {
    if (streamerTypes[values.streamerType]?.toLowerCase() !== "event" || initialStreamerType === "event") {
      try {
        await apiClient.patch("trainers/update-profile", {
          json: {
            eventTicketPrice: values.eventTicketPrice,
            username: values.username,
            name: values.name,
            bio: values.bio,
            certifications: values.certifications,
            streamerType: streamerTypes[values.streamerType]?.toLowerCase(),
            motto: values.motto,
            currency: currency,
            categories: categoryListIds.reduce((categories: number[], category, _) => {
              values.categories.map((value) => {
                if (category === categoryListIds[value]) categories.push(category);
              });
              return categories;
            }, []),
          },
        });
        if (!self.data?.trainerProfileFilled)
          await apiClient.put("trainers/set-profile-filled", {
            json: {},
          });

        await queryClient.invalidateQueries("ownTrainerProfile");
        await queryClient.invalidateQueries("self");

        alert?.success(translate("common:edit.changes-saved-successfully") ?? "Changes saved successfully.");
      } catch (err) {
        if (err instanceof HTTPError)
          alert?.error(`${translate("common:edit.failed-to-save-account-data")} (${(await err.response.json()).error.message})}`);
      }
    } else {
      modalContext.show(ConfirmationModal, {
        action: async () => {
          try {
            await apiClient.patch("trainers/update-profile", {
              json: {
                username: values.username,
                name: values.name,
                bio: values.bio,
                certifications: values.certifications,
                streamerType: streamerTypes[values.streamerType]?.toLowerCase(),
                motto: values.motto,
                categories: categoryListIds.reduce((categories: number[], category, _) => {
                  values.categories.map((value) => {
                    if (category === categoryListIds[value]) categories.push(category);
                  });
                  return categories;
                }, []),
              },
            });
            if (!self.data?.trainerProfileFilled)
              await apiClient.put("trainers/set-profile-filled", {
                json: {},
              });

            await queryClient.invalidateQueries("ownTrainerProfile");
            await queryClient.invalidateQueries("self");

            alert?.success(translate("common:edit.changes-saved-successfully") ?? "Changes saved successfully.");
          } catch (err) {
            if (err instanceof HTTPError)
              alert?.error(`${translate("common:edit.failed-to-save-account-data")} (${(await err.response.json()).error.message})}`);
          }
          modalContext.hide();
        },
        title: "Are you sure you want to tranform your account to Event?",
        description: "Since you've selected Event as Streamer Type, after clicking Save Profile, you won't be able to change your account type.",
        confirmButtonLabel: "Save Profile",
      });
    }
  };
  const accountConnectionData = connections.data;
  type Partner = {
    id: string;
    user: User;
  };
  let partners: Partner[] = [];
  if (accountConnectionData?.connectedBy && accountConnectionData?.connectedTo) {
    partners = [
      ...accountConnectionData?.connectedBy.map((connection) => ({
        id: connection.id,
        user: connection.user,
      })),
      ...accountConnectionData?.connectedTo.map((connection) => ({
        id: connection.id,
        user: connection.account,
      })),
    ];
  }
  // console.log(partners);
  // console.log(accountConnectionData);

  const deleteConnection = async (connectionId: string) => {
    try {
      const response = await apiClient.delete(`account-connect/${connectionId}`);
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      } else {
        connections.refetch();
      }
    } catch (err) {
      console.error(err);
    }
  };
  const declineConnection = async (connectionId: string) => {
    try {
      const response = await apiClient.patch(`account-connect/decline/${connectionId}`);
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      } else {
        connections.refetch();
      }
    } catch (err) {
      console.error(err);
    }
  };
  const acceptConnection = async (connectionId: string) => {
    try {
      const response = await apiClient.patch(`account-connect/accept/${connectionId}`);
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      } else {
        connections.refetch();
      }
    } catch (err) {
      console.error(err);
    }
  };
  const setStreamerType = function (index: number) {
    if (data) {
      setChanged(true);
      data.streamerType = streamerTypes[index];
    }
  };

  useEffect(() => data && setLayoutProps({ trainerUsername: data.slug as string }), [data]);

  const categories = useCategories();

  const coverImageInputRef = useRef<HTMLInputElement>(null);
  const profileImageInputRef = useRef<HTMLInputElement>(null);
  const logoImageInputRef = useRef<HTMLInputElement>(null);
  const [profileImageUrl, setProfileImageUrl] = useState<ImageSourceSet | null>(null);
  const [coverImageUrl, setCoverImageUrl] = useState<ImageSourceSet | null>(null);
  const [logoUrl, setLogoUrl] = useState<ImageSourceSet | null>(null);

  let deleteLogo = null;
  let profileImageInfo = null;

  if (logoUrl) {
    deleteLogo = (
      <Button
        style={{ margin: "0 auto" }}
        variant="text"
        onClick={() =>
          modalContext.show(ConfirmationModal, {
            action: async () => {
              await apiClient.delete(`users/delete-logo-image`);
              await queryClient.invalidateQueries(["self"]);
              setLogoUrl(null);
              modalContext.hide();
            },
            title: translate("common:edit.are-you-sure-title"),
            description:
              translate("common:edit.are-you-sure-description") ??
              "After clicking delete logo image, your logo will be permanently deleted and cannot be restored.",
            confirmButtonLabel: translate("common:edit.delete-logo-image"),
          })
        }
        size="min"
        icon={<IconDelete />}
      ></Button>
    );
  }

  if (self.data?.roles.includes("trainer")) {
    profileImageInfo = (
      <div style={{ maxWidth: "180px" }}>
        <ul style={{ listStyleType: "disc" }}>
          <li style={{ paddingTop: "14px", marginLeft: "14px" }}>{translate("common:edit.profile-photo-suggestion")}</li>
          <li style={{ paddingTop: "14px", marginLeft: "14px" }}>{translate("common:edit.use-transparent-logo")}</li>
        </ul>
      </div>
    );
  }

  const introductionVideoQuery = useQuery(["userIntroductionVideo", data?.id], () => getVideoByID(data?.introductionVideoId!), {
    enabled: !!data?.introductionVideoId,
  });

  useEffect(() => {
    if (data) {
      if (data.profileImagePaths) setProfileImageUrl(data.profileImagePaths);
      setCoverImageUrl(data.coverImagePaths);
      setLogoUrl(data.logoImagePaths);
      setCurrency(data.currency);
    }
  }, [data]);

  if (!data || !categories.data || (!introductionVideoQuery.data && introductionVideoQuery.isLoading) || !self.data) return null;

  const categoryListItems = categories.data.map((category) => category.name);
  const categoryListIds = categories.data.map((item: any) => item.id);

  const media: VidAndThumb | null = introductionVideoQuery.data
    ? {
        thumbnail: null,
        video: {
          filePath: introductionVideoQuery.data.url,
          vodId: introductionVideoQuery.data.vodId,
          length: introductionVideoQuery.data.length,
          downloadFileKey: introductionVideoQuery.data.downloadFileKey!,
        },
      }
    : null;
  const initialValues = {
    eventTicketPrice: self.data.eventTicketPrice ?? 0,
    username: data.username,
    name: data.name,
    motto: data.motto,
    bio: data.bio,
    certifications: data.certifications,
    streamerType: data.streamerType ? streamerTypes.indexOf(data.streamerType?.charAt(0).toUpperCase() + data.streamerType?.slice(1)) : 0,
    categories: data.categories
      ? categoryListIds.reduce((categories: number[], item, i) => {
          data.categories.map((category) => {
            if (item === category) categories.push(i);
          });
          return categories;
        }, [])
      : [],
  };

  return (
    <div className="relative">
      <div className="relative flex items-center justify-center h-96 group hover:opacity-80 transition-opacity">
        <LazyLoadedImg className="absolute inset-0 opacity-80" srcSet={coverImageUrl ?? {}} />
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            if (!coverImageInputRef.current || !coverImageInputRef.current.files) return;
            const formData = new FormData();
            formData.append("file", coverImageInputRef.current.files[0]);
            setCoverImageUrl(
              (
                (await apiClient
                  .post("users/upload-cover-image", {
                    body: formData,
                  })
                  .json()) as any
              ).urls
            );
            return false;
          }}
        >
          <input
            className="hidden"
            type="file"
            name="file"
            ref={coverImageInputRef}
            onChange={(e) => e.currentTarget.form?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}
          />
          <Button type="button" className="opacity-0 group-hover:opacity-100" onClick={() => coverImageInputRef.current?.click()}>
            {translate("common:edit.change-cover-photo")}
          </Button>
        </form>
      </div>
      <div
        className={`z-50 ${
          isMobileAppView && !isLandscape ? "scale-75 md:left-5" : "md:left-10"
        } absolute flex-col space-y-4 -top-14 left-0 -right-96 md:right-auto md:top-60 justify-center`}
      >
        <div className="flex flex-col space-y-4 items-center">
          <LazyLoadedImg className="w-60 h-60 md:h-80 rounded-xl" srcSet={profileImageUrl ?? "/profile.svg"} />
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              if (!profileImageInputRef.current || !profileImageInputRef.current.files) return;
              const formData = new FormData();
              formData.append("file", profileImageInputRef.current.files[0]);
              setProfileImageUrl(
                (
                  (await apiClient
                    .post("users/upload-profile-image", {
                      body: formData,
                    })
                    .json()) as any
                ).urls
              );
              self.refetch();
              return false;
            }}
          >
            <input
              className="hidden"
              type="file"
              name="file"
              ref={profileImageInputRef}
              onChange={(e) => e.currentTarget.form?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}
            />
            <Button size="min" variant="text" type="button" onClick={() => profileImageInputRef.current?.click()}>
              {profileImageUrl ? translate("common:edit.edit-profile-picture") : translate("common:edit.upload-profile-picture")}
            </Button>
          </form>
        </div>
        <div className="flex flex-col space-y-4 items-center">
          {logoUrl && <LazyLoadedImg className="w-60 h-28 rounded-xl" srcSet={logoUrl} />}
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              if (!logoImageInputRef.current || !logoImageInputRef.current.files) return;
              const formData = new FormData();
              formData.append("file", logoImageInputRef.current.files[0]);
              setLogoUrl(
                (
                  (await apiClient
                    .post("users/upload-logo-image", {
                      body: formData,
                    })
                    .json()) as any
                ).urls
              );
              return false;
            }}
          >
            <input
              className="hidden"
              type="file"
              name="file"
              ref={logoImageInputRef}
              onChange={(e) => e.currentTarget.form?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}
            />
            <Button size="min" variant="text" type="button" onClick={() => logoImageInputRef.current?.click()} style={{ margin: "0 auto" }}>
              {translate("common:edit.edit-profile-logo")}
            </Button>
            {deleteLogo}
            {profileImageInfo}
          </form>
        </div>
      </div>

      <div className="relative flex m-24 mt-0 pt-10 mx-auto max-w-8xl justify-center">
        <div className="w-full max-w-2xl space-y-10">
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .nullable()
                .required(translate("common:edit.a-name-is-required") ?? "A name is required"),
              username: Yup.string()
                .nullable()
                .required(translate("common:edit.a-username-is-required") ?? "A username is required."),
              bio: Yup.string()
                .nullable()
                .required(translate("common:edit.a-bio-is-required") ?? "A bio is required."),
              certifications: Yup.string().nullable(),
              //TODO: Add translation
              motto: Yup.string()
                .nullable()
                .max(
                  150,
                  translate("common:edit.a-bio-can-only-be-x-characters", {
                    charCount: 150,
                  }) ?? "A bio can only be 150 characters."
                )
                .required(translate("common:edit.a-motto-is-required") ?? "A motto is required."),
            })}
            initialValues={initialValues}
            onSubmit={handleEditFormSubmit}
          >
            {({ resetForm, isSubmitting }) => (
              <Form className="space-y-4">
                <h2>{translate("common:edit.profile-settings")}</h2>
                <InputField name="username" label={translate("common:edit.username") ?? "Username"} />
                <InputField name="name" label={translate("common:edit.name") ?? "Name"} />
                <InputField name="motto" label={translate("common:edit.motto") ?? "Motto"} rows={3} multiline />
                <InputField name="bio" label={translate("common:edit.personal-bio") ?? "Personal bio"} rows={5} multiline />
                <InputField name="certifications" label={translate("common:edit.certifications") ?? "Certifications"} rows={5} multiline />
                <MultiSelectListBoxField
                  name="categories"
                  label={translate("common:edit.categories")}
                  placeholder={translate("common:edit.select-category")}
                  items={categoryListItems}
                />

                <ListBoxField
                  readonly={initialStreamerType === "event"}
                  name="streamerType"
                  className="flex-1"
                  label={translate("common:edit.streamer-type") ?? "Streamer type"}
                  placeholder={translate("common:edit.select-streamer-type") ?? "Select your streaming type"}
                  items={streamerTypes}
                  onChange={(index) => {
                    setStreamerType(index);
                  }}
                />
                {initialStreamerType === "event" && (
                  <div className="flex gap-4">
                    <ListBoxField
                      name="currency"
                      className="flex-1"
                      label={translate("common:finance-dashboard.currency") ?? "Currency"}
                      placeholder={currency ?? "Select currency"}
                      items={currencies}
                      onChange={(index) => {
                        setCurrency(currencies[index]);
                      }}
                    />
                    <InputField
                      min={0}
                      type="number"
                      name="eventTicketPrice"
                      label={translate("common:edit.ticket-price") ?? "Ticket price"}
                      currency
                      currencyType={currency || "EUR"}
                    />
                  </div>
                )}
                <div className="flex">
                  <Button type="submit" loading={isSubmitting}>
                    {translate("common:edit.save-changes")}
                  </Button>
                  <Button type="button" variant="text" onClick={() => resetForm()}>
                    {translate("common:cancel")}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          <Formik
            initialValues={{ media: media }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={Yup.object().shape({
              media: Yup.object()
                .nullable()
                .shape({
                  video: Yup.object().nullable(),
                  thumbnail: Yup.mixed().nullable(),
                })
                .test(
                  "has-vid-and-thumb",
                  translate("common:edit.video-and-thumbnail-must-be-uploaded") ?? "Video and thumbnail must be uploaded",
                  (value) => !!value?.video && !!value?.thumbnail
                ),
            })}
            onSubmit={async (values) => {
              try {
                const fData = new FormData();
                fData.append("file", values.media?.thumbnail!);

                const thumb: ThumbnailUploadResponse = await apiClient.post(`streams/upload-thumbnail-image`, { body: fData }).json();

                await apiClient.post("videos/introduction-video", {
                  json: {
                    vodId: values.media?.video?.vodId,
                    filePath: values.media?.video?.filePath,
                    thumbnailImageId: thumb.imageId,
                    downloadFileKey: values.media?.video?.downloadFileKey,
                  },
                });
                alert?.success(translate("common:edit.introduction-video-saved-successfully") ?? "Introduction video saved successfully");
              } catch (e) {
                if (e instanceof HTTPError)
                  alert?.error(`${translate("common:edit.failed-to-upload-introduction-video")} (${(await e.response.json()).error.message})`);
              }
            }}
          >
            {({ values, isSubmitting }) => (
              <Form>
                <div className="space-y-3">
                  <p>{translate("common:edit.about-me")}</p>
                  <VidAndThumbField
                    name="media"
                    initialThumbnailImagePath={introductionVideoQuery.data?.thumbnailImagePaths}
                    postUrl="videos/upload-file"
                  />
                  <Button type="submit" loading={isSubmitting} disabled={!values.media || !values.media.thumbnail?.type}>
                    {translate("common:edit.save-introduction-video")}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          <div className="flex items-center center">
            <h2>{translate("common:edit.account-settings")}</h2>
            <Button
              className="ml-auto"
              variant="text"
              size="min"
              onClick={() =>
                modalContext.show(ConfirmationModal, {
                  action: async () => {
                    try {
                      await apiClient.delete("users");
                      alert?.success(translate("common:edit.account-deleted-successfully"));
                      useUserStore.getState().setAccessToken(null);
                      await router.push("/home");
                    } catch (err) {
                      alert?.error(
                        `${translate("common:edit.failed-to-delete-account")} ${
                          err instanceof HTTPError ? `(${(await err.response.json()).error.message})` : ""
                        }`
                      );
                    }
                  },
                  title: translate("common:edit.are-you-sure-delete"),
                  description:
                    translate("common:edit.are-you-sure-delete-desc") ??
                    "After clicking delete profile, your profile and all your content will be permanently deleted and cannot be restored.",
                  confirmButtonLabel: translate("common:edit.delete-profile"),
                })
              }
            >
              Delete account
            </Button>
          </div>
          <Formik
            initialValues={{
              email: data.email,
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={Yup.object().shape({
              email: Yup.string().required(),
            })}
            onSubmit={async (values) => {
              try {
                await apiClient.post("change-email/init", {
                  json: { email: values.email },
                });
                modalContext.show(EmailSentModal, {});
              } catch (err) {
                alert?.error(
                  `${translate("common:edit.failed-to-send-email")} ${
                    err instanceof HTTPError ? `(${(await err.response.json()).error.message})` : ""
                  }`
                );
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="space-y-4">
                  <InputField name="email" label="Email" />
                  <Button type="submit" loading={isSubmitting}>
                    {translate("common:edit.change-email")}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          <Formik
            initialValues={{
              password: "",
              newpassword: "",
              newpasswordagain: "",
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string().required(translate("common:edit.please-enter-current-password") ?? "Please enter your current password"),
              newpassword: Yup.string().required(translate("common:edit.please-enter-new-password") ?? "Please enter the new password"),
              newpasswordagain: Yup.string().oneOf(
                [Yup.ref("newpassword"), null],
                translate("common:edit.passwords-must-match") ?? "Passwords must match"
              ),
            })}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values) => {
              try {
                await apiClient.put("change-password", {
                  json: {
                    currentPassword: values.password,
                    newPassword: values.newpassword,
                    newPasswordConfirm: values.newpasswordagain,
                  },
                });
                alert?.success(translate("common:edit.password-changed-successfully"));
              } catch (err) {
                alert?.error(
                  `${translate("common:edit.failed-to-change-password")} ${
                    err instanceof HTTPError ? `(${(await err.response.json()).error.message})` : ""
                  }`
                );
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="space-y-4">
                  <InputField
                    name="password"
                    label={translate("common:edit.change-password") ?? "Change password"}
                    placeholder={translate("common:edit.current-password") ?? "Current password"}
                    type="password"
                  />
                  <InputField
                    name="newpassword"
                    label={translate("common:edit.new-password") ?? "New password"}
                    placeholder={translate("common:edit.new-password") ?? "New password"}
                    type="password"
                  />
                  <InputField
                    name="newpasswordagain"
                    label={translate("common:edit.confirm-new-password") ?? "Confirm new password"}
                    placeholder={translate("common:edit.confirm-new-password") ?? "Confirm new password"}
                    type="password"
                  />
                  <Button type="submit" loading={isSubmitting}>
                    {translate("common:edit.change-password")}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          {accountConnectionData && (
            <div className="w-full flex gap-5 flex-col ">
              <h2>{translate("common:edit.partners")}</h2>
              {partners && partners.length > 0 ? (
                <div className="flex flex-col gap-5">
                  {partners.map((partner) => {
                    return (
                      <div className="flex justify-between items-center w-full p-3 rounded-xl text-gray-400 bg-gray-700" key={partner.id}>
                        <div className="flex gap-5 items-center">
                          <LazyLoadedImg srcSet={partner.user?.profileImagePaths} className="w-8 h-8 rounded-full" />
                          <div>{partner.user?.name}</div>
                        </div>
                        <div className="flex items-center gap-5">
                          <Button
                            variant="secondary"
                            size="min"
                            className="peer text-gray-900 w-8 h-8 sm:mx-auto ml-auto"
                            icon={<IconClose className="w-5 h-5" />}
                            awaitOnClick={() => deleteConnection(partner.id)}
                          ></Button>
                          <span className="peer-hover:text-white transition">{translate("common:edit.delete-this-connection")}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div>{translate("common:edit.you-have-no-connections-yet")}</div>
              )}
            </div>
          )}
          <div className="w-full flex gap-5 flex-col ">
            <h2>{translate("common:edit.incoming-connection-requests")}</h2>
            {accountConnectionData?.incomingRequests && accountConnectionData?.incomingRequests.length > 0 ? (
              <div className="flex flex-col gap-5">
                {accountConnectionData?.incomingRequests.map((connection) => {
                  return (
                    <div className="flex justify-between items-center w-full p-3 rounded-xl text-gray-400 bg-gray-700" key={connection.id}>
                      <div className="flex gap-5 items-center">
                        <LazyLoadedImg srcSet={connection.user.profileImagePaths} className="w-8 h-8 rounded-full" />
                        <div>{connection.user.name}</div>
                      </div>
                      <div className="flex gap-5 items-center">
                        <Button
                          variant="circle"
                          size="min"
                          awaitOnClick={() => acceptConnection(connection.id)}
                          className=" text-gray-900 w-8 h-8 sm:mx-auto ml-auto"
                          icon={<IconCheck className="w-5 h-5" />}
                        ></Button>
                        <Button
                          variant="secondary"
                          size="min"
                          awaitOnClick={() => declineConnection(connection.id)}
                          className=" text-gray-900 w-8 h-8 sm:mx-auto ml-auto"
                          icon={<IconClose className="w-5 h-5" />}
                        ></Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>{translate("common:edit.you-have-no-incoming-connection-requests-yet")}</div>
            )}
          </div>
          <div className="w-full flex gap-5 flex-col ">
            <h2>{translate("common:edit.outgoing-connection-requests")}</h2>
            {accountConnectionData?.outgoingRequests && accountConnectionData?.outgoingRequests.length > 0 ? (
              <div className="flex flex-col gap-5">
                {accountConnectionData?.outgoingRequests.map((connection) => {
                  return (
                    <div className="flex justify-between items-center w-full p-3 rounded-xl text-gray-400 bg-gray-700" key={connection.id}>
                      <div className="flex gap-5 items-center">
                        <LazyLoadedImg srcSet={connection.account.profileImagePaths} className="w-8 h-8 rounded-full" />
                        <div>{connection.account.name}</div>
                      </div>
                      <div className="flex gap-5 items-center">
                        <Button
                          variant="secondary"
                          size="min"
                          className=" text-gray-900 w-8 h-8 sm:mx-auto ml-auto"
                          icon={<IconClose className="w-5 h-5" />}
                          awaitOnClick={() => deleteConnection(connection.id)}
                        ></Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>{translate("common:edit.you-have-no-outgoing-connection-requests-yet")}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardEdit.layout = MainLayout;
DashboardEdit.guard = "trainer";
export default DashboardEdit;
