const colors = require("tailwindcss/colors");

/** @type {import("tailwindcss/tailwind-config").TailwindConfig} */
module.exports = {
  mode: "jit",
  content: [
    "./src/pages/**/*.{js,ts,jsx,tsx}",
    "./src/components/**/*.{js,ts,jsx,tsx}",
  ],
  darkMode: "media", // or 'media' or 'class'
  theme: {
    extend: {
      boxShadow: {
        "category-card-inner": "inset 0px 0px 50px 16px rgba(0,0,0,0.40)",
        "courses-card-glow": "0px 0px 11px 0px rgba(255, 163, 81, 0.5)",
      },
      dropShadow: {
        "category-card-text": "0px 0px 4px #000000",
      },
      blur: {
        xs: "1px",
      },
      backdropBlur: {
        xs: "1px",
      },
      width: {
        128: "32rem",
      },
      height: {
        128: "32rem",
      },
      maxWidth: {
        "8xl": "110rem",
        "7.5xl": "95rem",
      },
      zIndex: {
        "-10": "-10",
        900: "900",
        1000: "1000",
      },
      cursor: {
        none: "none",
      },
      borderWidth: {
        3: "3px",
      },
    },
    colors: {
      primary: {
        DEFAULT: "#ffa351",
        hover: "#f8841c",
      },
      wgreen: "#64B77F",
      crimson: "#D42D36",
      gray: {
        400: "#b2b2b2",
        700: "#2a2a2a",
        800: "#212121",
        900: "#141414",
      },
      transparent: "transparent",
      current: "currentColor",
      black: colors.black,
      white: colors.white,
      indigo: colors.indigo,
      red: colors.red,
      yellow: colors.amber,
      green: colors.green,
    },
    fontSize: {
      base: ".75rem",
      normal: "1rem",
      md: "1.1rem",
      lg: "1.333rem",
      xl: "2.5rem",
      "2xl": ["5rem", "6rem"],
    },
  },
  plugins: [],
};
