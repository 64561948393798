import React, { useEffect, useState } from "react";
import { screens } from "../lib/screens";

type MQViewProps = {
  query: string;
  children: React.ReactNode;
};

const MQView: React.FC<MQViewProps> = ({ children, query }) => {
  const [shouldRender, setShouldRender] = useState<boolean>();

  useEffect(() => {
    const op = query[0];
    const screen = query.slice(1);
    const screenSize = screens[screen];
    if (!screenSize) throw new Error("MQView: {screen} is not a valid Tailwind screen size");

    let mqString = "";

    switch (op) {
      case ">":
        mqString = `(min-width: ${screenSize})`;
        break;
      case "<":
        mqString = `(max-width: ${screenSize})`;
        break;
      default:
        throw new Error("Invalid operator, must be either < or >");
    }

    const listener = (e: MediaQueryListEvent) => setShouldRender(e.matches);

    const mq = window.matchMedia(mqString);
    mq.addEventListener("change", listener);
    setShouldRender(mq.matches);

    return () => mq.removeEventListener("change", listener);
  }, []);

  if (shouldRender) return <>{children}</>;
  return null;
};

export default MQView;
