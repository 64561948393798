import resolveConfig from "tailwindcss/resolveConfig";
import { Config } from "tailwindcss/types/config";

import tailwindConfig from "../../tailwind.config"; // Your tailwind config

//type Screens = Record<"sm" | "md" | "lg" | "xl" | "2xl", string>;
type Screens = Record<string, string>;

const fullConfig = resolveConfig(tailwindConfig as unknown as Config);
const screensFromConfig = fullConfig?.theme?.screens as Screens;

const screensDefault: Screens = {
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  "2xl": "1536px",
};

export const screens = screensFromConfig || screensDefault;
